<template>
  <LiefengContent :isBack="true"  @backClick="backClick">
    <template #title>敏感词管分类理
    </template>
    <template #toolsbarRight>
      <Form
        inline
        label-colon 
        >
        
        <Button
          type="primary"
          style="margin-right: 10px"
          @click="type='add';changeAddStatus(true)"
          >新增分类</Button
        >
      </Form>
      
    </template>
    <template #contentArea>
      <LiefengTable
        :tableData="tableData"
        :talbeColumns="talbeColumns"
        :loading="loading"
        :pageSize="pageSize"
        :pagesizeOpts="[20, 30, 50, 100, 200]"
        :total="total"
        :fixTable="true"
        :curPage="page"
        @hadlePageSize="hadlePageSize"

      ></LiefengTable>
     

      <LiefengModal 
        :title="type=='add'?'新增分类':'修改分类'"
        :value="addStatus"
        @input="changeAddStatus"
        height="130px"
        width='530px'
        >
        <template v-slot:contentarea>
            <Form
                :label-colon="true"
                :label-width="120"
            >
              <FormItem>
                <span slot="label" class="validate">分类名称:</span>
                <Input style="width:300px" v-model.trim="name" :maxlength="20"></Input>
              </FormItem>
            </Form>
        </template>
        <template v-slot:toolsbar>
             <Button type="primary" style="marginRight:10px" @click="save">保存</Button>
             <Button style="marginRight:10px" @click="name = ''; changeAddStatus(false)">取消</Button>
          </template>
      </LiefengModal>
    </template>
  </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3";
import LiefengModal from "@/components/LiefengModal";
import LiefengTable from "@/components/LiefengTable";
export default {
  data() {
    return {
      tableData: [],
      talbeColumns: [
       
        {
          title: "分类名称",
          key: "catName",
          minWidth: 200,
          align: "center",
        },
        {
          title: "敏感词数量（个）",
          minWidth: 200,
          align: "center",
          render:(h,params) => {
            return h('div',{},params.row.num?params.row.num:0)
          }
        },
        {
          title: "创建时间",
          key: "gmtCreate",
          minWidth: 140,
          align: "center",
          render:(h,params) => {
              return h('div',{},params.row.gmtCreate ? this.$core.formatDate(
                  new Date(params.row.gmtCreate),
                  "yyyy-MM-dd hh:mm:ss"
              ):'')
          }
        },
        {
          title: "操作",
          minWidth: 200,
          align: "center",
          render: (h, params) => {
            return h("div", [
              h(
                "Button",
                {
                  props: {
                    type: "info",
                    size: "small",
                  },
                  style: {
                    marginLeft: "14px",
                  },
                  on: {
                    click: () => {
                      this.getDetail(params.row);
                    },
                  },
                },
                "修改"
              ),
              h(
                "Button",
                {
                  props: {
                    type: "error",
                    size: "small",
                  },
                  style: {
                    marginLeft: "14px",
                  },
                  on: {
                    click: () => {
                      this.delSort(params.row);
                    },
                  },
                },
                "删除"
              ),
            ]);
          },
        },
      ],
      loading: false,
      pageSize: 20,
      total: 0,
      page: 1,
      // 分类信息
      name:'',
      id:'',
      addStatus:false,
    };
  },
  methods: {
    // 返回按钮
    backClick(){
        this.$router.push('/wordsmanage')
    },
    //分页
    hadlePageSize(val){
        this.page = val.page
        this.pageSize = val.pageSize
        this.getList()
    },
    // 新增框状态事件
    changeAddStatus(status){
        this.addStatus = status
    },
    // 查询
    search(){
    },
    // 获取详情修改
    getDetail(data){
      this.type = 'edit'
      this.name = data.catName,
      this.id = data.id
      this.addStatus = true
    },
    
    save(){
      if(!this.name.trim()){
        this.$Message.warning({
          content:"分类名称不能为空",
          background:true
        })
        return
      }
      // 新增
      if(this.type == 'add'){
        this.$post('/dictlist/api/lfcommon/pc/sen/saveSenCat',{
          catName:this.name
        }).then( res => {
          if(res.code == 200){
            this.$Message.success({
              content:"新增成功",
              background:true
            })
            this.addStatus = false
            this.getList()
          }else{
            this.$Message.error({
              content:"新增失败",
              background:true
            })
          }
        })
      }else{
        this.$post('/dictlist/api/lfcommon/pc/sen/updateSenCat',{
          catName:this.name,
          id:this.id
        }).then( res => {
          if(res.code == 200){
            this.$Message.success({
              content:"修改成功",
              background:true
            })
            this.addStatus = false
            this.getList()
          }else{
            this.$Message.error({
              content:"修改失败",
              background:true
            })
          }
        })
      }
      
      
    },
    //删除
    delSort(data){
      this.$Modal.confirm({
        title: "温馨提示",
        content: `是否删除<span style='color:red'> ${data.catName} </span>`,
        onOk: () => {
          this.$post('/dictlist/api/lfcommon/pc/sen/deleteSenCat',{
            id:data.id
          }).then(res => {
            if(res.code == 200){
              this.$Message.success({
                content:"删除成功",
                background:true
              })
              this.getList()
            }else{
              this.$Message.error({
                content:"删除失败",
                background:true
              })
            }
          })
        }
      })
    },
    
    //获取分类列表
    getList(){
      this.loading = true
      this.$get('/dictlist/api/lfcommon/pc/sen/selectSenCatPage',{
          page:this.page,
          pageSize:this.pageSize
      }).then( res => {
        this.loading = false
          if(res.code == 200){
            this.tableData = res.dataList
            this.tableData = res.dataList
            this.total = res.maxCount
          }else {
            this.$Message.error({
              content:"数据获取失败",
              background:true
            })
          }
      })
    },
   
  },
  created() {
    this.getList()
    
  },
  components: {
    LiefengContent,
    LiefengModal,
    LiefengTable,
  },
};
</script>
    
<style scoped lang='less'>
/deep/.validate {
  &::before {
    content: "*";
    display: inline-block;
    margin-right: 4px;
    line-height: 1;
    font-family: SimSun;
    font-size: 14px;
    color: #ed4014;
  }
}

</style>